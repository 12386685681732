export default [
  {
    name: 'Drew Johnson',
    position: 'Chief Executive Officer',
    imgUrl: 'Drew_Johnson',
  },
  {
    name: 'Cory McAboy',
    position: 'Chief Techology Officer',
    imgUrl: 'Cory_McAboy_2',
  },
  {
    name: 'Kimina Andersen',
    position: 'Chief Operating Officer',
    imgUrl: 'Kimina_Andersen',
  },
  {
    name: 'Jamil Islam',
    position: 'Director of QA',
    imgUrl: 'Jamil_Islam',
  },
  {
    name: 'Bekah Hanson',
    position: 'Lead Product Designer',
    imgUrl: 'Bekah_Hanson',
  },
  {
    name: 'Liz Hamilton',
    position: 'Delivery Manager',
    imgUrl: 'Liz_Hamilton',
  },
  {
    name: 'Adrian Tamayo',
    position: 'Sr. Android Engineer',
    imgUrl: '',
  },
  {
    name: 'Anthony Harvey',
    position: 'Sr. iOS Engineer',
    imgUrl: 'Anthony_Harvey',
  },
  {
    name: 'Daniel Cilli',
    position: 'Lead iOS Engineer',
    imgUrl: 'Daniel_Cilli',
  },
  {
    name: 'John Rowan',
    position: 'Sr. Android Engineer',
    imgUrl: 'John_Rowan',
  },
  {
    name: 'John Ugwuadi',
    position: 'Sr. Android Engineer',
    imgUrl: 'John_Ugwuadi',
  },
  {
    name: 'Jordan Osterberg',
    position: 'Sr. iOS Architect',
    imgUrl: 'Jordan_Osterberg',
  },
  {
    name: 'Kingsley Okoli',
    position: 'QA Analyst',
    imgUrl: 'Kingsley_Okoli',
  },
  {
    name: 'Matt Lee',
    position: 'Sr. Frontend Engineer',
    imgUrl: 'Matthew_Lee',
  },
  // {
  //   name: 'Nabeel Javed',
  //   position: 'Android Engineer',
  //   imgUrl: 'Nabeel_Javed',
  // },
  {
    name: 'Nelson Aquino Jr',
    position: 'iOS Engineer',
    imgUrl: 'Nelson_Aquino_Jr',
  },
  {
    name: 'Neri Quiroz-Cabrera',
    position: 'Android Engineer',
    imgUrl: '',
  },
  {
    name: 'Maxwell Johnson',
    position: 'Backend Engineer',
    imgUrl: 'Maxwell_Johnson',
  },
  {
    name: 'Ashley Smith',
    position: 'iOS Engineer',
    imgUrl: 'Ashley_Smith',
  },
  {
    name: 'Andy Lochan',
    position: 'iOS Engineer',
    imgUrl: 'Andy_Lochan',
  },
  {
    name: 'Haroune Kechaoui',
    position: 'Android Engineer',
    imgUrl: 'Haroune_Kechaoui',
  },
  {
    name: 'Lowell Pence',
    position: 'iOS Engineer',
    imgUrl: 'Lowell_Pence',
  },
  {
    name: 'Dominique Chin-Jones',
    position: 'Backend Engineer',
    imgUrl: 'Dom_ChinJones',
  },
  {
    name: 'Toby Sack',
    position: 'Android Engineer Apprentice',
    imgUrl: 'Toby_Sack',
  },
  {
    name: 'Mars Dixon',
    position: 'QA Analyst',
    imgUrl: 'Mars_Dixon',
  },
  {
    name: 'Zachary Thomas',
    position: 'Delivery Manager',
    imgUrl: 'Zachary_Thomas',
  },
  {
    name: 'Kaleb Rench',
    position: 'Delivery Manager',
    imgUrl: '',
  },
  {
    name: 'Tega Oboraruvwe',
    position: 'Frontend Engineer',
    imgUrl: 'Tega_Oboraruvwe',
  },
  {
    name: 'Guilherme Henrique Pinto',
    position: 'Backend Engineer',
    imgUrl: 'Guilherme_Henrique_Pinto',
  },
  {
    name: 'Kate Ma',
    position: 'Jr. Product Designer',
    imgUrl: 'Kate_Ma',
  },
  {
    name: 'Kamel Gaouaoui',
    position: 'Backend Engineer',
    imgUrl: 'Kamel_Gaouaoui',
  },
  {
    name: 'Gabriel Almeida',
    position: 'Backend Engineer',
    imgUrl: '',
  },
  {
    name: 'Lucas Pereyra',
    position: 'Backend Engineer',
    imgUrl: 'Lucas_Pereyra',
  },
  {
    name: 'Lizandra Tirado',
    position: 'Human Resources Generalist',
    imgUrl: 'Lizandra_Tirado',
  },
  {
    name: 'Chris Bressler',
    position: 'Product Designer',
    imgUrl: 'Chris_Bressler',
  },
];
// In cloudinary, add the image to the folder '/RapptrSite/Headshots'
